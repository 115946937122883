<template>
  <div>
    <!--begin::DocumentVQA-->
    <div class="row">
      <div class="col-lg-8 col-xxl-5">
        <div
          role="alert"
          v-bind:class="{ show: errors && errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
        <b-form @submit="onSubmit">
          <b-form-group
            id="input-group-0"
            label="Output files"
            label-for="files"
            description="Provide .jsonl files with your model outputs. You can provide output
            files for multiple tasks and various seeds at once."
          >
            <b-form-file
              multiple
              size="md"
              id="files"
              v-model="form.files"
              placeholder=""
              accept=".jsonl,.json"
            >
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
                <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                  + {{ names.length - 1 }} More files
                </b-badge>
              </template>
            </b-form-file>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Name of the solution"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              v-model="form.name"
              placeholder="Enter name"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Description"
            label-for="textarea-rows"
            description="Description of the solution (can be edited or filled later)"
          >
            <b-form-textarea
              id="textarea-rows"
              placeholder="Provide description (optional)"
              v-model="form.description"
              rows="8"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
            id="input-group-1"
            label="Link to the publication"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.link"
              placeholder="Enter link (optional)"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-rules">
            <b-form-checkbox-group v-model="form.checked" id="rules">
              <b-form-checkbox value="protocol"
                >I followed the
                <router-link to="/rules">Evaluation Protocol</router-link> or
                will not make the results public.
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

          <b-button type="submit" variant="primary">Submit</b-button>
        </b-form>
      </div>
    </div>
    <!--end::DocumentVQA-->
  </div>
</template>

<script>
import { UPLOAD } from "@/core/services/store/auth.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
// import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
// import StatWidget7 from "@/view/content/widgets/stats/Widget12.vue";
// import Paper from "@/view/content/widgets/due/Paper.vue";

export default {
  data() {
    return {
      form: {
        link: "",
        name: "",
        description: "",
        checked: [],
        files: []
      }
    };
  },
  name: "upload",
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let formData = new FormData();
      [].forEach.call(this.form.files, function(file) {
        formData.append("files", file);
      });
      formData.append("link", this.form.link);
      formData.append("name", this.form.name);
      formData.append("description", this.form.description);
      formData.append("checked", this.form.checked.length);

      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.$store
        .dispatch(UPLOAD, formData)
        .then(data => {
          this.$router.push({ name: "details", params: { id: data.id } });
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        })
        .catch(() =>
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading")
        );
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Evaluate Solution" }]);
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
